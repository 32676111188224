<template>
    <div class="personalDetail">
        <app-header :title="title" :isShowBack="true"></app-header>
        <div class="info">
            <div class="base-info">
                <div class="info-title">基本信息</div>
                <div class="row">
                    <div class="row-title">注册主体类型</div>
                    <el-input class="row-value" v-model="typeStr" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">{{type == 1 ? '姓名' : '企业名称'}}</div>
                    <el-input class="row-value" v-model="name" disabled></el-input>
                </div>
                <div v-if="type == 2">
                    <div class="row">
                        <div class="row-title">统一信用代码</div>
                        <el-input class="row-value" v-model="creditCode" disabled></el-input>
                    </div>
                    <div class="row">
                        <div class="row-title">企业法人姓名</div>
                        <el-input class="row-value" v-model="legalName" disabled></el-input>
                    </div>
                </div>

                <div class="row">
                    <div class="row-title">{{type == 1 ? '性别' : '法人性别'}}</div>
                    <el-input class="row-value" v-model="sexStr" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">{{type == 1 ? '证件类型' : '法人证件类型'}}</div>
                    <el-input class="row-value" v-model="cardTypeStr" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">{{type == 1 ? '证件号码' : '法人证件号码'}}</div>
                    <el-input class="row-value" v-model="cardNoStr" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">证件有效期</div>
                    <el-input class="row-value" v-model="validate" disabled></el-input>
                </div>
                <div class="row address">
                    <div class="row-title">联系地址</div>
                    <el-input type="textarea" class="row-value" :autosize="{ maxRows: 6}" v-model="address" disabled></el-input>
                </div>
                <div v-if="type == 2">
                    <div class="row" style="display: flex;">
                        <div class="row-title">法人身份证国徽面</div>
                        <img v-if="identityReverseUrl" style="width: 120px;height:120px;" :src="identityReverseUrl">
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="row-title">法人身份证人像面</div>
                        <img v-if="identityFrontUrl" style="width: 120px;height:120px;" :src="identityFrontUrl">
                    </div>
                    <div class="row" style="display: flex;">
                        <div class="row-title">营业执照</div>
                        <img v-if="certificateUrl" style="width: 120px;height:120px;" :src="certificateUrl">
                    </div>
                </div>

            </div>
            <!-- 绑定了账户才展示  -->
            <div class="base-info" v-if="bindState">
                <div class="info-title">{{type == 1 ? '银行账户信息' : '银行账户信息（对公账户）'}}</div>
                <div class="row">
                    <div class="row-title">开户行名称</div>
                    <el-input class="row-value" v-model="openBank" disabled></el-input>
                </div>
                <div class="row address">
                    <div class="row-title">支行行名</div>
                    <el-input type="textarea" class="row-value" :autosize="{ maxRows: 6}" v-model="branchBank" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">支行行号</div>
                    <el-input class="row-value" v-model="branchBankNo" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">账户名称</div>
                    <el-input class="row-value" v-model="accountName" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">账号</div>
                    <el-input class="row-value" v-model="bankNo" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">{{type == 1 ? '银行预留手机号码' : '对公账户法人手机号码'}}</div>
                    <el-input class="row-value" v-model="bankPhone" disabled></el-input>
                </div>
            </div>

            <div class="base-info">
                <div class="info-title">手机号码（用于供应商登录）</div>
                <div class="row">
                    <div class="row-title">手机号码（登录账号）</div>
                    <el-input class="row-value" v-model="mobile" disabled></el-input>
                </div>
                <div class="row">
                    <div class="row-title">登录密码</div>
                    <el-input class="row-value" v-model="password" disabled></el-input>
                </div>
            </div>
            <div class="base-info">
                <div class="info-title">其他信息</div>
                <div class="row margin-top padding-bottom">
                    <div class="row-title">供应商审核</div>
                    <el-switch v-model="state" activeText="通过" inactiveText="不通过" @change="changeState(state)"
                        :disabled="checkState">
                    </el-switch>
                </div>
            </div>
            <div class="submit" v-if="!checkState">
                <el-button class="submit-button" type="primary" @click="submit" :loading="loading">提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '审核供应商',
                loading: false,
                state: false,
                checkState: false,
                typeStr: '',
                name: '',
                sexStr: '',
                cardTypeStr: '',
                cardNoStr: '',
                validate: '',
                address: '',
                openBank: '',
                branchBank: '',
                branchBankNo: '',
                accountName: '',
                bankNo: '',
                bankPhone: '',
                mobile: '',
                password: '',
                id: '',
                bindState: false,
                type: 1,

                identityFrontUrl: '',
                identityReverseUrl: '',
                certificateUrl: '',
                creditCode: '',
                legalName: '',

            }
        },
        created() {},
        activated() {
            if (this.$route.query.checkFlage == 2) {
                this.checkState = true;
                this.title = '查看详情';
            } else {
                this.checkState = false;
                this.title = '供应商审核';
            }
            this.type = this.$route.query.type;

            this.getDetail();
        },
        deactivated() {
            let pathList = this.$route.path.split('/')
            if(pathList[2] != 'merchantManage') {
                if(this.cache.get('supplierType')) {
                    this.cache.del('supplierType');
                }
            }
        },
        methods: {
            getDetail() {
                let dto = {
                    id: this.$route.query.id
                }
                this.post('/mall-service/merchant/v1/detail', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        let data = res.data.data;
                        this.typeStr = ['个人', '企业'][data.type - 1];
                        this.name = data.name;
                        this.sexStr = ['男', '女'][data.sex - 1];
                        this.state = data.state == 1;
                        if (data.cardType == 1) {
                            this.cardTypeStr = '身份证';
                        }
                        if(this.$route.query.checkFlage == 2) {//查看详情  证件号码不展示全部
                            this.cardNoStr = data.cardNo ? data.cardNo.slice(0,2) + '******' + data.cardNo.slice(data.cardNo.length - 2,data.cardNo.length) : '--';
                            this.bankNo = data.bankNo ? data.bankNo.slice(0,4) + '******' + data.bankNo.slice(data.bankNo.length - 4,data.bankNo.length) : '--';
                        }else {//审核  证件号码展示全部
                            this.cardNoStr = data.cardNo ? data.cardNo : '--';
                            this.bankNo = data.bankNo ? data.bankNo : '--';
                        }
                        
                        this.validate = data.validate;
                        this.address = data.address;
                        this.openBank = data.openBank;
                        this.branchBank = data.branchBank;
                        this.branchBankNo = data.branchBankNo;
                        this.accountName = data.accountName;
                        if(data.bankPhone) {
                            this.bankPhone = data.bankPhone.slice(0,3) + '****' +   data.bankPhone.slice(data.bankPhone.length - 4,data.bankPhone.length);
                        }else {
                            this.bankPhone = '--';
                        }
                        this.mobile = data.mobile;
                        this.id = data.id;
                        this.password = '******';
                        this.bindState = data.tranceNum ? true : false;
                        if (data.type == 2) {
                            this.identityFrontUrl = data.identityFrontUrl;
                            this.identityReverseUrl = data.identityReverseUrl;
                            this.certificateUrl = data.certificateUrl;
                            this.creditCode = data.creditCode;
                            this.legalName = data.legalName;
                        }
                    }

                })
            },
            submit() {
                this.loading = true
                let dto = {
                    id: this.id,
                    state: this.state ? 1 : 0,
                }
                this.post('/mall-service/merchant/v1/review', dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                        })
                        this.$back();
                    }
                    this.loading = false
                }).catch(() => {
                    this.loading = false
                })
            },
            changeState(val) {
            }
        }
    }
</script>
<style lang="scss" scoped>
    .info {
        margin-left: 100px;
    }

    .info-title {
        font-weight: 600;
        color: #333333;
        margin-bottom: 22px;
    }

    .row {
        margin-bottom: 10px;
    }
    .address {
        display: flex;
        align-items: center;
    }

    .row-title {
        display: inline-block;
        padding-right: 20px;
        width: 200px;
        text-align: right;
    }

    .row-value {
        width: 300px;
    }

    .submit {
        margin-left: 242px;
    }

    .submit-button {
        margin-top: 26px;
        margin-bottom: 20px;
        width: 200px;
    }

    .margin-top {
        margin-top: 30px;
    }

    .padding-bottom {
        padding-bottom: 20px;
    }

    .row-tip {
        color: #666666;
        font-size: 12px;
        margin-left: 220px;
        margin-top: 10px;
    }
</style>